<template>
  <div class="youTube">
    <YouTube :config='config'/>
  </div>
</template>
<script>
import YouTube from "@/components/youTube.vue";

export default {
  name: "video",
  props:['config'],
  components: {
    YouTube
  }
};
</script>

<style scoped>
.youTube {
  padding: 60px 100px;
  background-color: #f1f1f1;
}
::v-deep .galleryTitle {
  /* font-size: 65% !important; */
  line-height: 101% !important;
  padding: 10px 0px 0px 0px !important;
  color: #0b3f7e;
  font-weight: bold;
}
@media only screen and (max-width: 500px) {
  .youTube{
    padding: 60px 10px;
  }
}
</style>
